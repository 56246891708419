<template>
  <v-app>
    <v-container style="position: relative">
      <v-alert v-model="error" type="error" dismissible>
        {{dialogText}}
      </v-alert>
      <v-card class="mt-5">
        <v-row align="center" class="mt-n5">
          <v-col style="font-size:16px; text-align:right" cols="4" sm="4" md="2" lg="2">
            ロケーション名
          </v-col>
          <v-col cols="8" sm="8" md="10" lg="10">
            <v-select
              :items="locations"
              :value="this.id"
              item-text="name"
              item-value="dev_id"
              persistent-hint
              return-object
              v-on:change="changeId"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="this.id" align="center" class="mt-n5 mb-1">
          <v-col style="text-align:right" cols="3" sm="3" md="1" lg="1">
            <v-btn @click="getLastData(getSensorData)" :dark="!graphLoading" color="blue" class="ml-2" style="font-size:16px; height: 30px; width: 70px;" :loading="graphLoading" :disabled="graphLoading">
              <v-icon small>mdi-refresh</v-icon>更新
            </v-btn>
          </v-col>
          <v-col style="font-size:16px; text-align:right" cols="4" sm="4" md="2" lg="2">
            日付選択
          </v-col>
          <v-col cols="5" sm="5" md="9" lg="9">
            <v-menu>
              <template v-slot:activator="{on}">
                <v-btn v-on="on" text style="align:left"><v-icon>mdi-calendar</v-icon>{{mydate}}</v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-date-picker v-model="mydate" v-on:change="changeDay"></v-date-picker>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <div v-if="this.id  && this.existData">
          <template v-for="item in dataSetsKey">
            <label
              class="ml-5"
              :for="'check_${item.index}'"
              :key="item.label"
              :style="`color: ${item.color}; margin: 8px; font-size:16px;`">
              <input
                :id="`check_${item.index}`"
                type="checkbox"
                v-model="filterItem"
                :value="item.index"/>
                {{ item.label }}
            </label>
          </template>
          <line-chart
            class="mb-3"
            :height="height"
            :width="width"
            :chart-data="filteredDataCollection"
            :options="options"
          />
        </div>
        
        <div v-else>
          <p v-if="this.id  && !this.existData" class="text-subtitle-2 grey--text text--lighten-1" align="center">
            No Data Available
          </p>
        </div>
      </v-card>
      
      <v-card class="mt-2" v-if="this.id">
        <div class = "ml-2" style="font-size:16px">
          注意、警告アラーム履歴
        </div>
        <v-data-table
          :headers="errheaders"
          :items="errlistitems"
          :items-per-page="10"
          item-key="timestamp"
          class="elevation-1 my-3"
        >
          <template v-slot:[`item.errtype`]="{ item }">
            <v-chip label color="rgba(255, 255, 255, 0.0)" v-if="item.errtype==1">〇</v-chip>
          </template>
        <!-- エラーチェック -->
          <template v-slot:[`item.co2`]="{ item }">
            <v-chip label color="rgba(255, 255, 255, 0.0)" :class="getColorCo2Alarm(item.errtype)">{{ item.co2 }}</v-chip>
          </template>
          <template v-slot:[`item.airflow`]="{ item }">
            <v-chip label color="rgba(255, 255, 255, 0.0)" :class="getColorVal(item, sensorsThreshold[item.dev_id].warningMaxAir, sensorsThreshold[item.dev_id].warningMinAir, sensorsThreshold[item.dev_id].cautionMaxAir, sensorsThreshold[item.dev_id].cautionMinAir, 2)">{{ item.airflow }}</v-chip>
          </template>
          <template v-slot:[`item.temperature`]="{ item }">
            <v-chip label color="rgba(255, 255, 255, 0.0)" :class="getColorWBGTAlarm(item.errtype)">{{ item.temperature }}</v-chip>
          </template>
          <template v-slot:[`item.humidity`]="{ item }">
            <v-chip label color="rgba(255, 255, 255, 0.0)" :class="getColorWBGTAlarm(item.errtype)">{{ item.humidity }}</v-chip>
          </template>
        </v-data-table>
      </v-card>
      
      <v-card class="mt-2" v-if="this.id">
        <!-- 全データ -->
        <div class="ml-2">日次データ一覧</div>
        <v-data-table
          :headers="headers"
          :items="listitems"
          :items-per-page="10"
          item-key="timestamp"
          class="elevation-1 my-3"
        >
          <!-- エラーチェック -->
          <template v-slot:[`item.co2`]="{ item }">
            <v-chip label color="rgba(255, 255, 255, 0.0)" :class="getColorVal(item, item.warningMaxCo2, item.warningMinCo2, item.cautionMaxCo2, item.cautionMinCo2, 1)">{{ item.co2 }}</v-chip>
          </template>
          <template v-slot:[`item.airflow`]="{ item }">
            <v-chip label color="rgba(255, 255, 255, 0.0)" :class="getColorVal(item, item.warningMaxAir, item.warningMinAir, item.cautionMaxAir, item.cautionMinAir, 2)">{{ item.airflow }}</v-chip>
          </template>
          <template v-slot:[`item.temperature`]="{ item }">
            <v-chip label color="rgba(255, 255, 255, 0.0)" :class="getColorVal(item, item.warningMaxTemp, item.warningMinTemp, item.cautionMaxTemp, item.cautionMinTemp, 3)">{{ item.temperature }}</v-chip>
          </template>
          <template v-slot:[`item.humidity`]="{ item }">
            <v-chip label color="rgba(255, 255, 255, 0.0)" :class="getColorVal(item, item.warningMaxTemp, item.warningMinTemp, item.cautionMaxTemp, item.cautionMinTemp, 4)">{{ item.humidity }}</v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
  import SensorData from '../js/SensorData'
  import LineChart from '../js/LineChart.js'
  export default {
    components: {
      LineChart,
    },
    data: () => ({
      apigwurl: process.env.VUE_APP_APIURL,
      mydate: null,
      height: window.innerHeight * 0.6,
      width: window.innerWidth / 2,
      id: null,
      headers: [
        { text: '日時', value: 'timestamp' },
        { text: 'CO2(ppm)', value: 'co2' },
        { text: '温度(℃)', value: 'temperature' },
        { text: '湿度(％)', value: 'humidity' },
        { text: '風速(cm/s)', value: 'airflow' },
      ],
      errheaders: [
        { text: '日時', value: 'timestamp' },
        { text: '切断検知', value: 'errtype' },
        { text: 'CO2(ppm)', value: 'co2' },
        { text: '温度(℃)', value: 'temperature' },
        { text: '湿度(％)', value: 'humidity' },
        { text: '風速(cm/s)', value: 'airflow' },
      ],
      //プルダウン
      locations:[
        {dev_id: new String(), name: "No device"}
      ],
      graphLoading: false,
      //グラフ部
      daydata: null,
      datacollection:{
        label:[],
        datasets:[]
      },
      options: {
        responsive: true,
        scales: {
          xAxes: [{
            type: 'time',
            ticks:{
              autoSkip: true,
              maxTicksLimit: 5,
              stepSize: 5,
              fontSize: 6,
            },        
            gridLines: {
              display:false
            }
          }],
        },
        maintainAspectRatio: false,
      },
      labellist:[],
      co2data: [],
      tempdata: [],
      humdata: [],
      airdata: [],
      mailflgdata: [],
      sensorsThreshold: {},
      //データ部
      listitems:[],
      errlistitems:[],
      existData: false,
      isLastData: true,
      reload:'',
      dialogText:null,
      error:false,

      showAlertHistroy: false,
      
      filterItem: [],
    }),
    methods: {
      //セル色変更
      getColorVal:function(item, danmax, danmin, warmax, warmin, chkflg) {
        var val = ""
        switch(chkflg){
          case 1:
            if (item.co2){
              val = item.co2;
            }
            break;
          case 2:
            if (item.airflow){
              val = item.airflow;
            }
            break;
          //case 3:
          //  val = item.temperature;
          //  break;
          //  
          //case 4:
          //  val = item.humidity;
          //  break;
            
          //WBGT使用時はこっち
          case 3:
          case 4:
            if (item.temperature &&  item.humidity){
              val = SensorData.getWBGTData(item.temperature, item.humidity);
            }
            break;
        }

        if (val >= danmax || val <= danmin) return 'red--text font-weight-bold'
        else if (val >= warmax || val <= warmin) return 'orange--text font-weight-bold'
        else return 'black--text'
      },
      getColorCo2Alarm:function(errType) {
        if(errType == 2) return 'orange--text font-weight-bold'
        else if(errType == 3) return 'red--text font-weight-bold'
        else return 'black--text'
      },
      getColorWBGTAlarm:function(errType) {
        if(errType == 4) return 'orange--text font-weight-bold'
        else if(errType == 5) return 'red--text font-weight-bold'
        else return 'black--text'
      },
      //センサID取得
      changeId: function(dev){
        this.error =false
        this.id = dev.dev_id;
        this.$store.commit('setDevice', this.id)
        this.getSensorData();
      },
      
      //日付変更
      changeDay: function(){
        this.error =false
        this.isLastData = false;
        if(!this.isLastData){
          this.getSensorData();
        }
      },

      getLastData: function(callback){
        this.error =false
        this.isLastData = true;
        this.mydate = new Date().toISOString().substr(0, 10);
        callback();
      },
      
      //最新データ抽出
      getViewData: function(data){
        //初期化
        this.listitems = new Array();
        this.labellist = new Array();
        this.co2data = new Array();
        this.tempdata = new Array();
        this.humdata = new Array();
        this.airdata = new Array();
        this.mailflgdata = new Array();
        if(data.length > 0){
          if(this.isLastData){
            this.mydate = new Date(data[0].timestamp.split(' ')[0]).toISOString().substr(0, 10);
          }
          //グラフデータ
          for(var i = 0; i < data.length; i++){
            this.labellist.unshift(data[i]['timestamp'].split(' ')[1]);
            this.co2data.unshift(data[i]['co2']);
            this.airdata.unshift(data[i]['airflow']);
            this.tempdata.unshift(data[i]['temperature']);
            this.humdata.unshift(data[i]['humidity']);
            this.mailflgdata.unshift(data[i]['mail_send_flg']*100);
            //if(data[i]['mail_send_flg']  === 1){
            //  this.mailflgdata.unshift(data[i]['co2']);
            //} else {
            //  this.mailflgdata.unshift(-10);
            //}
            var threshold_data = Object.assign(data[i],this.sensorsThreshold[data[i].dev_id])
            this.$set(this.listitems, i, threshold_data)
          }
        }
      },

      getSensorInformation:function(callback){
        var numberSensors = 0;
        for(var groupid = 0; groupid < this.$store.state.sensorgroup.length; groupid++){
          var apiurl = this.apigwurl + "/sensors" + "?sensorgroupid=" + this.$store.state.sensorgroup[groupid] + "&loginid=" + this.loginuser;
          this.$axios.get(apiurl, null).then(res => {
            for(var i = 0; i < res.data.length; i++){
              var removeDevId = "";
              if(res.data[i].dev_id.indexOf('evs_') != -1){
                removeDevId = res.data[i].dev_id.replace('evs_','');
              }
              else{
                removeDevId = res.data[i].dev_id;
              }
              var devInfo = {
                dev_id:removeDevId,
                name:res.data[i].dev_name,
              }
              this.$set( this.locations, numberSensors, devInfo );
              var threshold  = {
                cautionMaxCo2: res.data[i].co2_cautionThresholdHigh,
                cautionMinCo2: res.data[i].co2_cautionThresholdLow,
                warningMaxCo2: res.data[i].co2_warningThresholdHigh,
                warningMinCo2: res.data[i].co2_warningThresholdLow,
                cautionMaxAir: res.data[i].airflow_cautionThresholdHigh,
                cautionMinAir: res.data[i].airflow_cautionThresholdLow,
                warningMaxAir: res.data[i].airflow_warningThresholdHigh,
                warningMinAir: res.data[i].airflow_warningThresholdLow,
                cautionMaxTemp: res.data[i].temperature_cautionThresholdHigh,
                cautionMinTemp: res.data[i].temperature_cautionThresholdLow,
                warningMaxTemp: res.data[i].temperature_warningThresholdHigh,
                warningMinTemp: res.data[i].temperature_warningThresholdLow,
                cautionMaxHum: res.data[i].humidity_cautionThresholdHigh,
                cautionMinHum: res.data[i].humidity_cautionThresholdLow,
                warningMaxHum: res.data[i].humidity_warningThresholdHigh,
                warningMinHum: res.data[i].humidity_warningThresholdLow,
              }
              this.sensorsThreshold[res.data[i].dev_id] = threshold;
              numberSensors++;
            }
            this.locations.sort(function(a, b){
              if(a.name < b.name){
                return -1;
              } else {
                return 1;
              }
            });
            if (callback!=null){
              callback();
            }
          })
          .catch((err) => {
            console.log(err);
            this.dialogText = this.$store.state.sensorgroup[groupid] + 'のセンサの取得に失敗しました。';
            this.error = true;
            this.returnTop('auto');
          });
        }
        // callback();
      },
      getAlarmData:function(apiurl,dev){
        this.errlistitems = new Array();
        this.$axios.get(apiurl).then(res => {
          var alarmData = res.data[dev];
          this.errlistitems = alarmData;
          if (this.errlistitems.length>0 ){
            this.showAlertHistroy=true
          }
          else{
            this.showAlertHistroy=false
          }
          this.fillData(this.showAlertHistroy);
          this.filterItem = this.datacollection.datasets.map((_s, i) => i);
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'エラーメール一覧の取得に失敗しました。';
          this.error = true;
          this.returnTop('auto');
          this.fillData(null);
          this.graphLoading = false;
          this.filterItem = this.datacollection.datasets.map((_s, i) => i);
        });
      },
      
      getSensorData:function(){
        this.graphLoading = true;
        var apiurlData = new String();
        var apiurlAlarm = new String();
        var initsensor = JSON.parse(JSON.stringify(this.locations[0]));
        if(initsensor.dev_id == null){
          this.id = null;
          this.daydata = [];
          this.getViewData(this.daydata);
          this.errlistitems = [];
          this.fillData(null);
          this.filterItem = this.datacollection.datasets.map((_s, i) => i);
        }
        else{
          if(this.id == null){
            this.id = initsensor.dev_id
            this.$store.commit('setDevice', this.id)
          }
          var getDeviceInfoId = 'evs_' + this.id;
          if(this.isLastData){
            apiurlData = this.apigwurl + "/sensor_data/" + getDeviceInfoId + "/?date=lastdate";
          }
          else{
            apiurlData = this.apigwurl + "/sensor_data/" + getDeviceInfoId + "/?date=" + this.mydate;
          }
          this.$axios.get(apiurlData).then(res => {
            this.daydata = res.data[getDeviceInfoId];
            if(this.daydata.length == 0){
              this.dialogText = '指定のデータは存在しません。';
              this.error = true;
              this.returnTop('auto');
              setTimeout(()=> {this.graphLoading = false;},1000);
              this.existData = false;
              this.daydata = [];
              this.getViewData(this.daydata);
              this.errlistitems = [];
              this.fillData(null);
              this.filterItem = this.datacollection.datasets.map((_s, i) => i);
            }
            else{
              this.existData = true;
              this.getViewData(this.daydata);
              apiurlAlarm = this.apigwurl + "/alarm_data/" + getDeviceInfoId + "/?date=" + this.mydate;
              this.getAlarmData(apiurlAlarm,getDeviceInfoId);
            }
          })
          .catch((err) => {
            console.log(err);
            this.dialogText = '日次データ一覧の取得に失敗しました。';
            this.error = true;
            this.returnTop('auto');
            this.graphLoading = false;
            this.existData = false;
            this.daydata = [];
            this.getViewData(this.daydata);
            this.errlistitems = [];
            this.fillData(null);
            this.filterItem = this.datacollection.datasets.map((_s, i) => i);
          });
        }
      },

      fillData:function(errflg){
        this.datacollection=
        {
          labels: this.labellist,
          datasets: [
            {
              label: 'CO2(ppm)',
              data: this.co2data,
              pointRadius: 0.0,
              borderWidth : 1.5,
              borderColor: '#32CD32',
              backgroundColor : '#32CD32',
              fill: false,
              type: 'line',
              lineTension: 0.4,
              yAxisID: 'y-axis-2',
            },
            {
              label: '温度(℃)',
              data: this.tempdata,
              pointRadius: 0.0,
              borderWidth : 1.5,
              borderColor: '#FFA500',
              backgroundColor : '#FFA500',
              fill: false,
              type: 'line',
              lineTension: 0.4,
              yAxisID: 'y-axis-1',
            },
            {
              label: '湿度(%)',
              data: this.humdata,
              pointRadius: 0.0,
              borderWidth : 1.5,
              borderColor: '#1E90FF',
              backgroundColor : '#1E90FF',
              fill: false,
              type: 'line',
              lineTension: 0.4,
              yAxisID: 'y-axis-1',
            },
            {
              label: '風量(cm/s)',
              data: this.airdata,
              pointRadius: 0.0,
              borderWidth : 1.5,
              borderColor: '#800080',
              backgroundColor : '#800080',
              fill: false,
              type: 'line',
              lineTension: 0.4,
              yAxisID: 'y-axis-1',
            }
          ]
        }
        if(errflg){
          this.datacollection.datasets.push(
            {
              label: '注意、警告アラーム',
              data: this.mailflgdata,
              borderColor: '#FF0000',
              backgroundColor : '#FF0000',
              fill: true,
              type: 'bar',
              //type: 'scatter',
              yAxisID: 'y-axis-1',
              borderWidth: 0.1,
            }
          )
        }
        setTimeout(()=> {this.graphLoading = false;},1000);
      },

      returnTop(action) {
        window.scrollTo({
          top: 0,
          behavior: action
        })
      },
    },

    beforeMount(){
      var callback = null
      if(this.$store.state.selectdevice!=null){
        this.id = this.$store.state.selectdevice;
        callback = this.getSensorData
      }
      this.loginuser = this.$store.state.user.username
      this.getSensorInformation(callback);
      //this.mydate = new Date().toISOString().substr(0, 10);
    },
    
    computed: {
      filteredDataCollection() {
        const collection = {
          ...this.datacollection,
          datasets: this.datacollection.datasets.filter((_s, i) => {           
            return this.filterItem.includes(i);
          }),
        };
        return collection;
      },
      dataSetsKey() {
        return this.datacollection.datasets.map((s, i) => ({
          label: s.label,
          index: i,
          color: s.backgroundColor,
        }));
      },
    },

    beforeDestroy(){
      this.$store.commit('setDevice', null)
    }
  }
</script>