import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  name: 'chart',
  mixins: [reactiveProp],
  data () {
    return {
      options: {
        legend: {
          display: false,
          //position: 'bottom'
        },
        scales: {
          xAxes: [{
            categoryPercentage: 0.4,
            scaleLabel: {
              drawBorder:false
            }
          }],
          yAxes: [{
            id: 'y-axis-1',
            type: 'linear',
            position: 'left',
            gridLines: {
              display:false
            },
            ticks: {
              min: 0,
              max: 100,
              stepSize: 10,
            }
          },
          {
            id: 'y-axis-2',
            type: 'linear',
            position: 'right',
            gridLines: {
              display:false
            },
            ticks: {
              min: 0,
              stepSize: 100,
            }
          }]
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';
              if(label === 'メール送信'){
                label = '';
              }else{
                label += ': ';
                label += tooltipItem.yLabel;
              }
              return label;
            }
          }
        },
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
